// INFO: QNA 게시판 답글 등록
<script>
import TextViewer from '@/components/TextViewer.vue' // TextViewer 불러오기
import TextEditor from '@/components/TextEditor.vue' // TextEditor 불러오기
import { readQna } from '@/api/admin/qna' // qna 조회
import { readQnaComment, updateQnaComment, saveQnaComment, deleteQnaComment } from '@/api/admin/qnaComment' // qna 답변 조회, 수정, 저장, 삭제
import store from '@/store'

export default {
  name: 'adminBoardQNACreate',
  components: { TextViewer, TextEditor },
  data() {
    const boardNo = Number(this.$route.params.boardNo) // 파라미터로 받아온 게시글 번호

    const boardData = {
      boardName: 'qna',
      boardId: boardNo
    }

    return {
      user: '', // 게시글 작성자
      boardNo: boardNo,
      qnaPageNo: 0,
      boardData: boardData,
      commentNo: '', // 답변 번호
      qnaData: {}, // QNA 데이터
      commentData: {}, // QNA 답변 데이터
      commentTitle: '', // QNA 답변 제목
      commentContent: ''
    }
  },
  async mounted() {
    this.qnaPageNo = Number(this.$route.params.page) // qna 게시판 페이지 번호

    // 로그인한 사용자 정보
    const user = store.getters['userStore/info'].name
    this.user = user

    // 문의글 불러오기
    const loadQnaData = async () => {
      const data = await readQna(this.boardNo)
      this.qnaData = data
    }
    await loadQnaData()

    // 답글 불러오기
    const data = await readQnaComment(this.boardNo)

    if (data.code !== 'DB_DATA_NOT_FOUND') {
      this.commentData = data[0]
      this.commentNo = this.commentData.commentId
      this.commentTitle = this.commentData.title
      this.commentContent = this.commentData.content
    }
  },
  methods: {
    // ********** QNA 답변 수정 **********
    async update(boardNo, commentNo, data) {
      const obj = {
        title: data.title,
        content: await data.getHtmlAfterSaveImages(this.boardData),
        updatedAt: new Date().toLocaleDateString()
      }
      return await updateQnaComment(boardNo, commentNo, obj)
    },
    // ********** QNA 답변 추가 **********
    async insert(boardNo, data) {
      const saveQnaData = {
        qnaId: this.boardNo, // qna 번호
        userId: this.user, // 작성자
        labNo: 0, // 연구실 번호
        title: data.title, // 답변 제목
        content: ' ', // 답변 내용
        createdAt: new Date().toLocaleDateString(), // 작성일
        updatedAt: ' ' // 수정일
      }
      const result = await saveQnaComment(boardNo, saveQnaData)

      if (result?.code) return result

      this.boardData.boardId = result.qnaId

      const obj = {
        title: data.title,
        content: await data.getHtmlAfterSaveImages(this.boardData),
        updatedAt: new Date().toLocaleDateString()
      }

      return await updateQnaComment(result.qnaId, result.commentId, obj)
    },

    // ********** 답글 저장 **********
    // textEditor의 저장버튼을 숨겨두고 refs를 통해서 textEditor의 저장함수 saveContent를 불러온다.
    saveQnaBtn() {
      const editor = this.$refs.editor
      editor.saveContent()
    },

    // ********** 답글 수정 및 저장 **********
    async emitCommentData({ getHtmlAfterSaveImages }) {
      const data = {
        title: this.commentTitle,
        getHtmlAfterSaveImages
      }
      const result = this.commentNo ? await this.update(this.boardNo, this.commentNo, data) : await this.insert(this.boardNo, data)
      if (result?.code) return alert(result.message)

      alert('답변이 저장되었습니다.')
      this.goQnaList()
    },

    // ********** qna 목록으로 이동 **********
    goQnaList() {
      this.$router.push({
        path: `/admin/board/qna/${this.qnaPageNo}`
      })
    },

    // ********** 해당 qna 보기로 이동 **********
    goQnaView() {
      this.$router.push(`/community/qna/${Number(this.$route.params.boardNo)}`)
    },

    // ********** qna 답글 삭제 **********
    deleteComment() {
      // 컨펌창 띄우기
      const decideDelete = confirm('답글을 정말로 삭제하시겠습니까?')

      if (decideDelete === true) {
        deleteQnaComment(this.boardNo, this.commentNo)
        window.location.reload()
      } else {
        location.reload()
      }
    }
  }
}
</script>

<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h4>QNA 답글쓰기</h4>
  </nav>

  <div id="content-wrapper" class="d-flex flex-column">
    <div class="qnaBtnArea">
      <button class="e-btn wf__btn btn-primary" @click="goQnaList()">목록</button>
      <button class="e-btn wf__btn btn-success ml-2" @click="saveQnaBtn()">저장</button>
    </div>
    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <span class="qnaViewTitle">{{ qnaData.title }}</span>
              <input type="hidden" class="border-0" v-model="qnaData.title" readonly />
              <button class="e-btn wf__btn btn-primary" @click="goQnaView()">보러가기</button>
            </td>
          </tr>
          <tr>
            <th></th>
            <td class="qnaContent">
              <text-viewer v-if="!boardNo || qnaData.content" :propsData="qnaData.content" :boardData="boardData"></text-viewer>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="adminTable mt-4">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>답글 제목</th>
            <td>
              <input
                class="bg-light big"
                v-if="!commentNo || commentTitle"
                v-model="commentTitle"
                placeholder="제목을 입력해주세요"
                size="50"
              />
              <button v-if="commentData.commentId" class="ml-4 e-btn wf__btn btn-primary" @click="deleteComment()">삭제</button>
            </td>
          </tr>
          <tr>
            <th></th>
            <td class="">
              <text-editor
                class="w-full"
                :style="{ 'max-width': 'none' }"
                v-if="!commentData.commentId || commentData.content"
                :propsData="commentData.content"
                @emitData="emitCommentData"
                :boardData="boardData"
                :hideSave="true"
                height="250px"
                ref="editor"
              >
              </text-editor>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 버튼 구역 -->
  </div>
</template>

<style scoped>
#content-wrapper {
  max-width: 1280px;
}
.qnaContent {
  display: flex;
  overflow-y: scroll;
  height: 250px;
}
.qnaBtnArea {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.qnaViewTitle {
  width: max-content;
  margin-right: 1em;
}
.qnaContent > div > button {
  /* display: none; */
  margin-top: 50px;
}
</style>
